import consumer from "./consumer"

consumer.subscriptions.create("IotChannel", {
  connected() {
    console.log("connected")
  },

  disconnected() {
    console.log("disconnected")
  },

  received(data) {
    // console.log("data:", data)
    let table = document.getElementById("messages");
    let row = table.insertRow(0);
    let cell1 = row.insertCell(0);
    let cell2 = row.insertCell(1);
    let cell3 = row.insertCell(2);
    cell1.innerHTML = data.ts;
    cell2.innerHTML = data.topic;
    cell3.innerHTML = data.body;
  },

  notifications: function() {
    return this.perform('notifications');
  }
});
